<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <!-- type="primary" -->

        <a-button
          v-if="isShowBtns.indexOf('ContractManagement-/api/contract/add') > -1"
          @click="showDrawer('add')"
          >创建合同</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="AlreadyOrder"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="cid"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1200px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :row-selection="rowSelection"
    >
      <!-- :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->
      <!-- 创建时间 -->
      <span slot="createTime" slot-scope="text">
        {{ $Format(text) }}
      </span>
      <!--审核进度-->
      <span slot="acceptOrderState" slot-scope="text">
        <div :class="'acceptOrderstate' + text">
          {{ text | ReceiveOrdersState }}
        </div>
      </span>

      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a @click="showDrawer('view', record)">详情</a>
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('ContractManagement-/api/contract/update') > -1
          "
          @click="showDrawer('edit', record)"
          >编辑</a
        >
        <!-- @click="GoToOrderDetails(record, '合同详情')" -->
        <a-popconfirm
          title="确定删除合同?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteContract(record)"
          @cancel="cancel"
        >
          <a
            class="delName"
            v-if="
              isShowBtns.indexOf('ContractManagement-/api/contract/delete') > -1
            "
            >删除</a
          >
        </a-popconfirm>
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :visible="visible"
      @close="onClose"
      :width="700"
      :destroyOnClose="destroyOnClose"
    >
      <ContractManagementAdd
        v-if="ContractManagementAdd"
        :record="record"
        :type="type"
        @onClose="onClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import {
  GetContractList,
  ContractAdd,
  ContractDelete,
} from "@/api/apiJF/quotation";
import { GetCompanies } from "@/api/device";
import ContractManagementAdd from "./ContractManagementAdd.vue";
// 搜索条件
const queryItems = [
  {
    label: "创建时间",
    value: "createTime",
    type: "dateRange",
    range: ["creatStartTime", "creatEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "客户姓名",
    value: "coname",
    type: "input",
    defaultValue: "",
  },

  {
    label: "联系电话",
    value: "cophone",
    type: "input",
    defaultValue: "",
  },
  //   {
  //     label: "设计师",
  //     value: "receivingName",
  //     type: "input",
  //     defaultValue: "",
  //   },
  //   {
  //     label: "分公司",
  //     value: "companyIds",
  //     type: "select",
  //     selectOption: "CompanyListUnAll",
  //     defaultValue: "",
  //   },
];
const columns = [
  {
    title: "合同编号",
    width: "200px",
    dataIndex: "conly",
    ellipsis: true,
    align: "center",
  },
  {
    title: "合同名称",
    // width: "150px",
    dataIndex: "cname",
  },
  {
    title: "委托方(甲方)",
    width: "120px",
    dataIndex: "coname",
    align: "center",
    ellipsis: true,
  },
  {
    title: "联系电话",
    width: "120px",
    dataIndex: "cophone",
    align: "center",
  },
  {
    title: "创建时间",
    width: "200px",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    align: "center",
  },
  {
    title: "审核进度",
    width: "100px",
    dataIndex: "ccheckstate",
    align: "center",
    scopedSlots: { customRender: "ccheckstate" },
    ellipsis: true,
  },
  {
    title: "审核人",
    width: "110px",
    dataIndex: "ccheckBy",
    align: "center",
  },
  //   {
  //     title: "设计师",
  //     width: "110px",
  //     dataIndex: "createTime",
  //     align: "center",
  //   },
  //   {
  //     title: "分公司",
  //     width: "100px",
  //     dataIndex: "companyName",
  //     align: "center",
  //   },
  {
    title: "操作",
    width: "120px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "ContractManagement",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    ContractManagementAdd,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      // 查询参数
      queryParam: {
        creatStartTime: "",
        creatEndTime: "",
        coname: "",
        cophone: "",
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      destroyOnClose: true,
      ContractManagementAdd: false,
      visible: false,
      type: "",
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys, //必须return一下，不然选中项不能清空
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => ({
          props: {
            // disabled: record.state != "0", // state：订单状态（0待处理、1已取消、2已完成、3部分退货、4全部退货、5已退货）
            // name: record.name,
          },
        }),
      };
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.companyIds == "") {
            this.queryParam.companyIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("AlreadyOrderColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.companyIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return GetContractList(params).then((res) => {
          // console.log("合同列表", res);
          return res.data;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (
        this.queryParam.companyIds == "" ||
        this.queryParam.companyIds == undefined
      ) {
        this.queryParam.companyIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 删除合同
    deleteContract(record) {
      console.log("删除合同", record);
      let param = {
        // cid: record ? record.cid : this.selectedRowKeys.toString(),
        cId: record.cid,
      };
      const orderParam = ["cId"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      ContractDelete(params).then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.$message.success("删除合同成功");
          this.$refs.table.refresh(true);
          this.selectedRowKeys = [];
          this.selectedRows = [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cancel(e) {
      console.log(e);
      this.$message.error("取消删除");
    },
    showDrawer(type, record) {
      this.visible = true;
      this.ContractManagementAdd = true;
      this.type = type;
      this.record = JSON.stringify(record);
    },
    onClose() {
      this.visible = false;
      this.record = "";
      this.ContractManagementAdd = false;
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.table-button-flex {
  display: flex;
  justify-content: space-between;
}

.Goodsstate {
  color: #20b01d;
  background: rgba(84, 195, 82, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate0 {
  color: #ffba07;
  background: rgba(255, 202, 64, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate1 {
  color: #20b01d;
  background: rgba(84, 195, 82, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate2 {
  color: #2164d8;
  background: rgba(33, 100, 216, 0.2);
  border-radius: 4px;
  padding: 5px;
}

.delName {
  color: red;
  margin-left: 20px;
}
.editBtn {
  margin-left: 20px;
}
</style>
<template>
  <div>
    <div>
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="所属公司">
          <a-select
            v-model="formInline.cbcId"
            class="search-expand-value"
            :disabled="type != 'add' ? true : false"
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合同编号">
          <a-input
            v-model="formInline.conly"
            placeholder=""
            :disabled="type == 'view' ? true : false"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="合同名称">
          <a-input
            v-model="formInline.cname"
            placeholder=""
            :disabled="type == 'view' ? true : false"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="业主姓名">
          <a-input
            v-model="formInline.coname"
            placeholder=""
            :disabled="type == 'view' ? true : false"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="联系电话">
          <a-input
            v-model="formInline.cophone"
            placeholder=""
            :disabled="type == 'view' ? true : false"
          >
          </a-input>
        </a-form-model-item>
        <div class="uploadDiv">
          <!--   :action="urlConfig.baseUrl + '/files/api/file/upload'" -->
          <a-upload
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
            :supportServerRender="true"
            :customRequest="customRequest"
            :remove="removefiles"
            v-if="type == 'edit'"
          >
            <a-button> <a-icon type="upload" /> 上传合同附件 </a-button>
          </a-upload>

          <div v-if="type == 'view'" class="fileList_out">
            <div class="fileListTitle">合同附件</div>
            <div v-for="item in fileList" :key="item.uid">
              <a> {{ item.name }}</a>
              <a-icon
                type="download"
                @click="downloadFile(item)"
                class="downloadIcon"
              />
            </div>
          </div>
        </div>

        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import {
  ContractAdd,
  ContractUpdate,
  FileUpload,
  FileDelete,
  FileDownload,
} from "@/api/apiJF/quotation";
import { urlConfig } from "@/config/config";
export default {
  name: "MaterialCategoriesEdit",
  components: {},
  props: {
    record: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {
        cname: "", //合同名称
        coname: "", //客户姓名
        cophone: "", //联系电话
        cbcId: "", //分公司名称
        conly: "", //合同编号
        files: [], //合同附件
      },
      formInlineEdit: {
        cname: "", //合同名称
        coname: "", //客户姓名
        cophone: "", //联系电话
        cbcId: "", //分公司名称
        conly: "", //合同编号
        cid: "",
        // files: [], //合同附件
      },
      filters: { GetCompanies: [] },

      fileList: [
        // {
        //   uid: "-1",
        //   name: "xxx.png",
        //   status: "done",
        //   url: "http://www.baidu.com/xxx.png",
        // },
      ],
      fileListPath: [],
      // 文件地址
      pathUrl: urlConfig.baseUrl + "/files/api/file?filePath=",
      // pathUrl: urlConfig.baseUrl + "/files/api/file/download?filePath=",
      // + "/files/picture/snapshot/snapshotpicture?filePath="
    };
  },
  created() {
    // 获取所属信息
    GetCompanies().then((res) => {
      this.filters.GetCompanies = res.data;
    });
    // console.log(this.type, this.record);
  },
  mounted() {
    if (this.type == "view" || this.type == "edit") {
      let record = JSON.parse(this.record);
      // for (let key in this.formInlineEdit) {
      for (let el in record) {
        // if (key == el) {
        //   // this.formInlineEdit[key] = record[el];
        // }
        this.formInlineEdit[el] = record[el];
      }
      // }
      if (record.files.length > 0) {
        let files = record.files;
        files.forEach((el) => {
          this.fileList.push({
            uid: el.fid,
            name: el.fname,
            status: "done",
            url: el.fpath,
            // url: this.pathUrl + el.fpath,
          });
        });
      }
      this.formInline = this.formInlineEdit;
      console.log("this.formInline", this.formInline);
    } else {
    }
  },
  watch: {
    "formInline.ptBcId": {
      handler(newValue) {
        // console.log("formInline.ptBcId", newValue);
      },
    },
  },
  methods: {
    // 提交按钮
    handleSubmit(e) {
      this.updata();
    },
    updata() {
      if (this.type == "add") {
        // console.log("提交");
        let file = [];
        this.fileListPath.forEach((el) => {
          file.push({
            fname: el.name,
            fpath: el.path,
          });
        });
        let form = this.formInline;
        form.files = file;
        // console.log("add", form, this.formInline);
        ContractAdd(form).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        let file = [];
        this.fileListPath.forEach((el) => {
          file.push({
            fname: el.name,
            fpath: el.path,
          });
        });
        // console.log(" this.fileListPath", this.fileListPath);

        let form = this.formInline;
        form.files = file;
        // console.log(" form.files ", form.files);
        ContractUpdate(form).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
    },
    handleChange(info) {
      let fileList = [...info.fileList];

      //   // 1. Limit the number of uploaded files
      //   //    Only to show two recent uploaded files, and old ones will be replaced by the new
      //   fileList = fileList.slice(-2);

      //   // 2. read from response and show file link
      //   fileList = fileList.map((file) => {
      //     if (file.response) {
      //       // Component will show file.url as link
      //       file.url = file.response.url;
      //     }
      //     return file;
      //   });
      //   console.log("fileList", fileList);

      this.fileList = fileList;
    },
    customRequest(data) {
      console.log("customRequest", data);
      const value = new FormData();
      let file = data.file;
      value.append("file", file);
      value.append("filePath", "/contract");
      FileUpload(value).then((res) => {
        if (res.code == 200) {
          this.uploadLoading = false;
          data.onSuccess("");
          this.fileListPath.push({
            uid: data.file.uid,
            path: res.data,
            name: data.file.name,
          });
        }
      });
    },
    // 点垃圾桶触发的事件(删除文件)
    removefiles(file) {
      console.log(file, this.fileList); //file代表点击的文件，this.fileList代表总上传数
      // 通过文件的 uid对比 确定具体删除哪一个
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1);
          // console.log("item", item);
          // console.log(this.fileList);
          //   this.deleteFile()
        }
      });
      this.fileListPath.forEach((el, index) => {
        if (el.uid == file.uid) {
          this.fileListPath.splice(index, 1);
          this.deleteFile(el);
        }
      });
    },
    // 删除上传的文件
    deleteFile(filePath) {
      let params = {
        // filePath: "/contract/2024-10/28/304810a3f76b4bee85de69980b544785.docx",
        filePath: filePath.path,
      };
      FileDelete(params).then((res) => {
        if (res.code == 200) {
          //   this.fileListPath.filter((el) => el.path == filePath.path);
          //   this.fileList = this.fileList.filter((el) => el.uid == filePath.uid);
        }
      });
    },
    // 下载文件
    downloadFile(file) {
      console.log("downloadFile", file);
      // let params = {
      //   filePath: file.url,
      // };
      const downloadElement = document.createElement("a");
      // const href = window.URL.createObjectURL(blob); //创建下载的链接
      const href = this.pathUrl + file.url;
      console.log("href", href);
      downloadElement.href = href;
      downloadElement.download = file.name; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      // FileDownload(params).then((res) => {
      //   // console.log("res", res);
      //   // let blob = new Blob([res]);
      //   // console.log("blob", blob);

      //   const downloadElement = document.createElement("a");
      //   // const href = window.URL.createObjectURL(blob); //创建下载的链接
      //   const href = this.pathUrl + file.url;
      //   console.log("href", href);
      //   downloadElement.href = href;
      //   downloadElement.download = file.name; //下载后文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); //点击下载
      //   document.body.removeChild(downloadElement); //下载完成移除元素
      // });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
.uploadDiv {
  width: 50%;
  margin: 20px 0;
  //   text-align: left;
}
.downloadIcon {
  font-size: 18px;
  margin-left: 20px;
}
.fileList_out {
  margin-left: 110px;
  width: 100%;
  text-align: left;
  .fileListTitle {
    font-weight: 600;
    margin: 20px 0;
  }
}
</style>